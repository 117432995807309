@import '@ebay/skin/dist/details/details'

.expandable-list
    summary
        list-style-position: inside
        list-style-type: none
        --details-summary-foreground-color: black
        outline: none

        div
            display: flex
            align-items: center

            &.with-text
                flex-direction: column
                align-items: flex-start

    summary.section-title
        margin: 0 0 10px
        padding-left: 0

    h4.details__label
        margin: 0
        display: inline-block

    span.details__icon
        float: right
        margin-left: auto
        margin-right: 0
        display: inline-block
        @media #{$bp-desktop}
            margin-left: 16px
            float: unset
