div.estimates-dashboard
  max-width: 1184px
  margin: 0 auto
  @media #{$bp-mobile}
    padding: 0 $spacing-s

  a.header-btn
    background-color: var(--page-notice-information-background-color, #f1f8fe)

  h3.estimates-heading
    margin-bottom: 32px

  div.estimates-list
    padding: 0

  h3.estimate-title.mobile-only
    display: none

    @media #{$bp-mobile}
      display: initial
      margin: 5px 0

  div.estimates-row
    display: flex
    margin-bottom: 32px

    @media #{$bp-mobile}
      flex-wrap: wrap
      margin-top: 12px

    h3.estimate-title
      @media #{$bp-mobile}
        display: none

    div.estimate-img-wrapper
      width: 300px

      @media #{$bp-mobile}
        width: 150px

      img.estimate-thumbnail
        max-width: 300px
        max-height: 180px
        object-fit: cover
        width: 100%
        height: 100%

        @media #{$bp-mobile}
          max-width: 150px
          max-height: 90px

    div.estimate-action
      display: flex
      flex-direction: column
      @media #{$bp-mobile}
        margin-top: 15px

      a
        margin-top: auto

    div.estimate-info
      flex: 1
      padding-left: 20px
      display: flex
      flex-direction: column

      div.bottom
        margin-top: auto

      h3
        margin: 0
        font-size: 24px

      p.estimate-status
        font-weight: bold

        @media #{$bp-mobile}
          margin-top: 0

      p.estimate-status.estimated
          color: green

      p.estimate-status.pending-info
        color: red

      p.estimate-description
        font-size: 0.9em
        color: gray
        max-height: 32px
        overflow: hidden

        @media #{$bp-mobile}
          display: none

      p.estimate-date
        font-size: 0.9em
        margin-bottom: 0

        @media #{$bp-mobile}
          padding-top: 10px

      p.estimate-price.no-price
        display: none

        @media #{$bp-mobile}
          display: block

  div.estimation-summary
    padding: 0
    margin-bottom: 32px
    section.page-notice
      margin: 0
      grid-template-columns: 32px auto 219px
      @media #{$bp-mobile}
        display: flex

@media #{$bp-mobile}
  .estimates-dashboard
    .estimation-summary
      .page-notice
        flex-wrap: wrap
      div.btn.btn--secondary
        width: 100%
      a.header-btn
        width: 100%

    .estimate-action
      width: 100%
      a.fake-btn
        width: 100%

