// Grey
$color-white: #fff
$color-grey1: #f7f7f7
$color-grey2: #e5e5e5
$color-grey3: #c7c7c7
$color-grey4: #a2a2a2
$color-grey5: #707070
$color-grey6: #414141
$color-grey7: #111820
$color-grey8: #f5f5f5
$color-grey9: #191919
$color-black: $color-grey7

// Yellow
$color-y1: #fcf2bd
$color-y2: #f7e376
$color-y3: #fbcd25
$color-y4: #f7b100
$color-y5: #e58c02
$color-y6: #aa5404
$color-y7: #592e13

// Orange
$color-o1: #ffdec7
$color-o2: #feb786
$color-o3: #ff8559
$color-o4: #ff6932
$color-o5: #db3c07
$color-o6: #b03005
$color-o7: #5c1b05

// Red
$color-r1: #ffd1dd
$color-r2: #ffa2b6
$color-r3: #ff6383
$color-r4: #e0103a
$color-r5: #c4003a
$color-r6: #a00739
$color-r7: #680226

// Magenta
$color-m1: #fad8f0
$color-m2: #f5a0d9
$color-m3: #ea5fbc
$color-m4: #82187c
$color-m5: #a60d8a
$color-m6: #82187c
$color-m7: #500750

// Blue
$color-b1: #c5e5fb
$color-b2: #93c9ff
$color-b3: #659eff
$color-b4: #3665f3
$color-b5: #382aef
$color-b6: #2b0eaf
$color-b7: #121258

// Teal
$color-t1: #c2f2ef
$color-t2: #71e3e2
$color-t3: #1dcbca
$color-t4: #02a2ac
$color-t5: #01718f
$color-t6: #0e4a6c
$color-t7: #003147

// Green
$color-g1: #ccfdce
$color-g2: #9ef4a6
$color-g3: #5ee471
$color-g4: #28a443
$color-g5: #1bab49
$color-g6: #05823f
$color-g7: #07522c

// Lime
$color-l1: #f4fabe
$color-l2: #e9f577
$color-l3: #c9e43b
$color-l4: #accf02
$color-l5: #86b300
$color-l6: #4b7d06
$color-l7: #364f03

// Dark mode grey
$color-black-dark-mode: #171717
$color-grey1-dark-mode: #212121
$color-grey2-dark-mode: #393939
$color-grey3-dark-mode: #4d4d4d
$color-grey4-dark-mode: #6a6a6a
$color-grey5-dark-mode: #9c9c9c
$color-grey6-dark-mode: #ababab
$color-grey7-dark-mode: #dcdcdc
$color-white-dark-mode: $color-grey7-dark-mode

// Dark mode color
$color-b4-dark-mode: #5192ff
$color-b6-dark-mode: #94bcff
$color-m4-dark-mode: #d2a4cf
$color-r4-dark-mode: #e95367
$color-g4-dark-mode: #24bc6b
$color-g6-dark-mode: #30c550

// 6.5 Product aliases
$color-background-default: $color-white
$color-background-highlight: $color-grey1
$color-text-default: $color-grey7
$color-text-secondary: $color-grey5
$color-text-disabled: $color-grey3
$color-text-confirmation: $color-g6
$color-separator: $color-grey2
$color-image-border: $color-grey2
$color-action-disabled: $color-grey3
$color-action-primary: $color-b4
$color-action-secondary: $color-grey5
$color-action-hover: $color-b6
$color-action-secondary: $color-grey5
$color-action-destroy: $color-r4
$color-link-default: $color-action-primary
$color-link-hover: $color-action-hover
$color-link-visited: $color-m4
$color-status-attention: $color-r4
$color-status-confirmation: $color-g4
$color-status-information: $color-b4
$color-disabled: $color-grey3

// Secret Colours
$color-status-attention-light: #fff5f8
$color-status-confirmation-light: #effef0
$color-status-information-light: #f1f8fe
$color-textbox-invalid: #fef5f6
$color-textbox-invalid-dark-mode: #2a191c

// following variables added to bridge some gaps between ds4 to ds6
// these might be temporary...

$color-icon-disabled: $color-grey3
$color-icon-actionable-default: $color-text-default
$color-icon-actionable-hover: $color-action-primary
$color-icon-actionable-active: $color-text-default
$color-action-primary-disabled: $color-action-disabled
$color-action-disabled-opacity: 1
$color-flyout-box-shadow: rgba(199, 199, 199, 0.5)
$color-form-control-label: $color-grey6
$color-form-control-border: $color-grey2
$color-form-control-underline: $color-grey5
$color-form-control-background: $color-grey1
$color-form-control-foreground: $color-text-default
$color-form-control-disabled-background: $color-grey1
$color-form-control-disabled-placeholder: $color-grey4
$color-form-control-readonly-foreground: $color-grey4
$color-form-control-label-disabled: $color-grey4
$color-dropdown-item-hover-background: $color-grey2
$color-dropdown-item-active-background: $color-background-default
$color-filter-selected-background: #e1e8fd // non-palette colour
$color-filter-selected-hover-background: #c2d0fb // non-palette colour

// Deprecated product aliases
$color-action-visited: $color-link-visited
$color-divider: $color-separator
