@import '@ebay/skin/dist/fullscreen-dialog/fullscreen-dialog'

.fullscreen-dialog__window
  @media #{$bp-desktop}
    max-width: 960px
    margin: 0 auto auto
    height: 100%

.fullscreen-dialog__main
  margin: 0
  padding-left: $spacing-s
  @media #{$bp-desktop}
    margin-top: $spacing-m
    padding-left: $spacing-m
    padding-right: $spacing-s
