@mixin fixed-button
	position: fixed
	bottom: 0
	left: 0
	right: 0
	padding: $spacing-s
	background: $color-white

	@media #{$bp-desktop}
		position: static
		padding: 0

	&:after
		content: ''
		position: absolute
		width: 100%
		height: 30px
		left: 0
		bottom: 70px
		box-shadow: inset 0 -6px 10px -10px $color-grey3

		@media #{$bp-desktop}
			display: none
