/*******************************************************************************
 *
 *  C2C Estimate - Clean base style
 *
 *******************************************************************************/
body
    margin: 0

.row
    width: 100%
    box-sizing: border-box

.page
    top: 0
    left: 0
    right: 0
    bottom: 64px
    overflow: auto

    @media #{$bp-desktop}
        position: static
        margin: 0 auto

.platform
    position: absolute
    top: 0
    left: 0
    right: 0
    text-align: center
    z-index: 100
    line-height: 35px
    pointer-events: none

    span
        color: white
        padding: 5px 10px
        border-radius: 5px
        &.dev
            background: #32b050
        &.staging
            background: #E39517
        &.prod
            background: red

.survey-banner
    margin-top: 40px
    margin-bottom: 40px

    @media #{$bp-desktop}
        max-width: 1184px
        margin: 40px auto 40px

    h3
        font-size: 1.875rem
    h4
        font-size: 1rem
    a
        border: none
    .cross-banner
        cursor: pointer
        float: right
    .text-banner
        max-width: 343px
        font-size: 1rem
        h4
            font-weight: normal

.page-notice__main
    h3.page-notice__title,
    p,
    a
        font-size: 14px
        line-height: 20px
    a
        text-decoration: none
        color: #3665F3

