@import '@ebay/skin/dist/progress-stepper/progress-stepper'
@import '../global/color'

.progress-stepper
  margin-top: $spacing-m
  margin-bottom: $spacing-m
  overflow-y: hidden
  //--progress-stepper-active-color: #{$color-grey7}
  //--progress-stepper-badge-current-background-color: #{$color-grey7}
  //--progress-stepper-badge-current-border-color: #{$color-grey7}
  //--progress-stepper-badge-upcoming-background-color: #{$color-grey3}
  //--progress-stepper-text-light-color: #{$neutral-n5-color}

  @media #{$bp-desktop}
    align-self: center
    margin: 0

  .progress-stepper__icon > [role="img"]
    font-size: 12px
    font-weight: normal

.progress-stepper__text
  width: 110px

  h5
    font-size: inherit

.show__modal-categories
  --fake-link-color: #{$color-black}
  text-align: left
