.man-treasure
    height: 500px
    color: $color-white
    margin: 56px auto 90px
    max-width: 1184px

    background:
        repeat: no-repeat

    @media #{$bp-mobile}
        margin-bottom: 56px
        background:
            image: url('../images/footer_m.jpg')
            size: cover
            position: bottom

    @media #{$bp-desktop}
        display: flex
        align-items: center
        margin-top: 80px
        background:
            image: url('../images/footer.png')

    .get-started
        padding-top: 4px

        .fake-btn
            font-size: $font-size-16
            font-weight: bold
            color: $color-t6
            background-color: $color-white

    .man-treasure-text
        @extend .row
        @extend .full-width-topic
        overflow: auto
        padding-left: 32px

        @media #{$bp-desktop}
            box-sizing: border-box
            margin: 0 auto

        h3
            margin: 2rem 0 1rem
            font-size: $font-size-36
            color: inherit

            @media #{$bp-desktop}
                padding-right: 45%
                margin-top: 0
                font-size: 46px
                line-height: 1.2em

        p
            font-size: $font-size-20
            line-height: 28px
