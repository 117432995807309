$photo-width-mobile: 136px
$photo-width-desktop: 250px
$preview-size: 22vw
$preview-max-size: 300px

.photo-container
	display: flex
	margin: 0 (-$spacing-s)
	padding: 0 $spacing-s
	height: $photo-width-mobile
	overflow-y: hidden
	gap: $spacing-s

	@media #{$bp-desktop}
		margin: 0
		padding: 0
		flex-wrap: wrap
		height: auto

	> *
		flex-shrink: 0

@mixin photo-block
	width: $photo-width-mobile
	height: $photo-width-mobile
	box-sizing: border-box
	border: 1px solid $color-grey2
	border-radius: 4px
	background: $neutral-n1-color

	@media #{$bp-desktop}
		width: calc(50% - #{$spacing-xs})
		height: $preview-size
		max-height: $preview-max-size

	img
		width: 100%

.photo-list
	display: flex
	height: $photo-width-mobile
	//overflow-y: hidden
	gap: $spacing-s

	@media #{$bp-desktop}
		display: block
		width: 100%
		height: auto
		min-height: $preview-size

	@media #{$bp-desktop-wide}
		min-height: $preview-max-size
