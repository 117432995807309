@import '@ebay/skin/dist/tabs/tabs'

.tabs__items
	margin-bottom: 16px

.tabs-categories
	.slider-buttons
		margin-top: -64px
	h3
		padding-right: 76px

.tabs__item.btn
	margin: 8px 0
	display: inline-block
	white-space: nowrap
	--btn-secondary-background-color: var(--light-n-1, #F7F7F7)
	--btn-secondary-border-color: transparent
	--btn-secondary-foreground-color: var(--light-n-7, #191919)
	--color-state-primary-hover: transparent
	--menu-button-menuitem-disabled-foreground-color: var(--menu-button-menuitem-foreground-color, var(--color-foreground-primary))

	&.btn--primary
		font-weight: normal

.tabs__panel__image
	display: block
	width: 256px
	height: 256px
	margin-bottom: 16px

.tabs__panel__label
	color: var(--color-foreground-primary)

.tabs__content
	position: relative
	margin-top: 48px

.tabs__cell li > a
	text-decoration-color: var(--color-foreground-primary)
