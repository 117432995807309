.header
    position: relative

    .header-content
        position: relative
        padding: $spacing-s
        height: 72px

        @media #{$bp-desktop}
            height: 88px
            padding: 24px 0
            max-width: 1184px
            margin: 0 auto

        a
            text-decoration: none
            display: flex
            flex-direction: row-reverse
            align-items: center

    &:after
        content: ''
        position: absolute
        width: 100%

        @media #{$bp-desktop}
            height: 30px

.logo-ebay
    display: inline-block

    #svg-logo-estimation
        position: absolute
        width: 250px
        top: -38px
        left: 0px
        @media #{$bp-desktop}
            width: 350px
            top: -38px
            left: -24px

.icon-logout
    display: flex
    float: right
    align-items: center
    height: 40px

    @media #{$bp-mobile}
        font-size: 0.9em

    a
        &:visited
            color: #111820
        &:hover
            color: #111820

        &:active
            color: #111820
        div
            color: #111820
            @media #{$bp-desktop}
                margin-right: 8px

        .logout-bonjour, .logout-cta
            display: block
            @media #{$bp-mobile}
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis

            @media #{$bp-mobile-small}
                max-width: calc(100vw - 250px - 16px) // Full viewport width - logo width - header padding

        .logout-cta
            text-align: right
            
            @media #{$bp-desktop}
                font-size: 0.8em

.svg-icon-avatar
    width: 32px
    height: 32px
    display: block
    margin: auto
    float: right

    @media #{$bp-mobile}
        display: none
