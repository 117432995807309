.estimation-summary
	background-color: $neutral-n1-color
	padding: $spacing-s $spacing-s $spacing-m
	gap: $spacing-l

	@media #{$bp-desktop}
		padding: $spacing-s $spacing-m

	h2
		@media #{$bp-desktop}
			margin: $spacing-xs 0
			font-size: $font-size-30

	h3
		font-size: $font-size-20

	.estimation-summary-content
		@media #{$bp-desktop}
			display: flex
			flex-direction: row
			gap: $spacing-l
			align-items: center
			max-width: 1184px
			margin: 0 auto

		.estimation-summary-title
			padding-left: 0
			font-size: 24px
			line-height: 32px

			@media #{$bp-desktop}
				flex-basis: 100%
				font-size: 30px
				line-height: 40px

		p,
		button
			font-size: $font-size-14

	&.topic-suggested-price
		@media #{$bp-desktop}
			display: none
