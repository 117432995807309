.hero-image
    display: flex
    align-items: center
    min-height: 500px
    max-width: 1184px
    margin: 0 auto
    border-radius: 16px
    padding: 20px 0
    background-repeat: no-repeat
    background-size: cover

    @media #{$bp-desktop}
        background-position-y: bottom
        background-size: cover
        background-image: linear-gradient(90deg, rgba(0,0,0,0.46) 0%, rgba(255,255,255,0) 100%), url(../images/home-dweb.jpg)

.hero-mobile
    display: none
    @media #{$bp-mobile}
        display: block
        padding: 16px 0 100%
        margin: 0 16px
        background: url(../images/home.png) no-repeat
        background-size: contain

.hero
    @extend .row
    @extend .full-width-topic
    width: 100%

    @media #{$bp-desktop}
        max-width: 669px
        padding: 0 48px

    .hero-badge
        font-size: $font-size-14
        line-height: 20px
        color: #FFFFFF

    h1, h2
        margin-top: 10px
        margin-bottom: 0
        line-height: 46px
        font-weight: bold
        color: #FFFFFF
        @media #{$bp-desktop}
            font-size: 46px
        @media #{$bp-mobile}
            color: #000000
            font-size: 30px

    p
        font-size: $font-size-20
        line-height: 28px
        color: #FFFFFF
        margin-top: 15px
        margin-bottom: 25px
        @media #{$bp-mobile}
            color: #000000

    .hero-buttons
        display: inline-table
        @media #{$bp-desktop}
            display: inline-grid
            grid-template-columns: 1fr 1fr

        .cta-btn
            a.fake-btn
                border: none
                font-size: $font-size-16
                font-weight: bold
                color: $color-grey7
                background-color: #FFFFFF
                @media #{$bp-mobile}
                    background-color: #000000
                    color: #FFFFFF
        .btn--secondary
            a.fake-btn
                color: #FFFFFF
                font-weight: bold
                background-color: transparent
                border-color: #FFFFFF
                margin-top: 20px
                font-size: $font-size-16
                @media #{$bp-desktop}
                    margin: 0 16px
                    width: 100%
                @media #{$bp-mobile}
                    color: #000000
                    border-color: #000000
                    font-weight: normal

    #what-can-estimate
        padding-top: 24px
        a
            margin-right: 6px
            color: #FFFFFF
            font-size: $font-size-12
            @media #{$bp-mobile}
                color: #000000
        svg path
            position: absolute
            width: 10px
            height: 10px
            left: calc(50% - 10px/2)
            top: calc(50% - 10px/2)
            fill: #FFFFFF
