@import 'estimation-text'

.estimation-request-date
	padding: $spacing-xs 0 $spacing-s
	text-align: right
	color: $color-grey6

.suggested-price
	display: flex
	flex-direction: row

	@media #{$bp-desktop}
		gap: $spacing-l

	> *
		width: 50%

		@media #{$bp-desktop}
			width: auto

	&-label
		display: block

	&-value
		display: block
		margin-top: $spacing-xs
		font-size: $font-size-24
		font-weight: $font-weight-bold

.suggested-price-content
	h4
		margin-top: 0

.inline-notice
	margin-top: 0
	margin-bottom: $spacing-m

	.inline-notice__main
		p
			font-weight: $font-weight-regular

.fixed-button-list-item
	@include fixed-button
	text-align: center
	padding: $spacing-s
	display: flex
	flex-direction: column

	@media #{$bp-desktop}
		margin-top: $spacing-m

	#estimation_accepted-list-nugget-button
		background: none
		border: none
		color: $color-b4
		line-height: 24px
		padding-top: 10px
		padding-bottom: 10px
		font-weight: $font-weight-bold
		font-family: inherit
		font-size: inherit
		cursor: pointer

		&:hover
			color: $color-b6

	&::after
		bottom: 110px

	.button-note
		display: flex
		justify-content: center
		align-items: center
		gap: $spacing-xs
		margin-top: $spacing-s

.estimation-summary.page-notice-type-product
	padding-bottom: 1px

	@media #{$bp-desktop}
		max-width: 1184px
		margin: 0 auto
		padding: $spacing-xs 0
		background: none

.estimation-accepted-header
	h3
		margin: 0
		font-size: $font-size-20
		font-weight: $font-weight-regular

.estimation-accepted-progress-stepper
	display: none

	@media #{$bp-desktop}
		display: block
		width: 100%

