@import '@ebay/skin/dist/textbox/textbox'
@import '@ebay/skin/dist/checkbox/checkbox'
@import '@ebay/skin/dist/field/field'
@import '../global/color'
@import './dropzone'

.request-estimation
	@media #{$bp-desktop}
		margin: 0 auto
		max-width: 1184px

	.two-column
		padding-right: 0
		padding-left: 0

	h2
		font-size: $font-size-24

		@media #{$bp-desktop}
			font-size: $font-size-30
	div.page-notice-container
		@media #{$bp-desktop}
			margin: 0 auto
			max-width: 1280px
			padding: 0 $spacing-m

	section.page-notice--attention
		border-radius: 4px

	> h2
		@extend .topic
		display: none

		@media #{$bp-desktop}
			display: block

	> .estimation-summary
		padding-top: $spacing-s
		overflow: auto

		@media #{$bp-desktop}
			display: none

	.progress-stepper
		margin: $spacing-m 0

#request-form
	padding-bottom: 1rem

	@media #{$bp-desktop}
		padding-bottom: 3rem

	.estimation-summary
		display: none
		padding-top: 0

		@media #{$bp-desktop}
			display: block
			background-color: transparent

.fluid
	width: 100%

	.field__description
		text-align: right

.dimension-fields
	display: inline-table
	width: 100%
	&::after
		@media #{$bp-desktop}
			clear: both

.field-group
	@media #{$bp-desktop}
		width: 50%

	.field__label
		width: 60%

	.field__control
		@media #{$bp-desktop}
			margin-right: 35px
	&:nth-child(odd)
		@media #{$bp-desktop}
			float: left
	&:nth-child(even)
		@media #{$bp-desktop}
			float: right

.topic-photos
	display: flex
	flex-direction: column
	padding-top: $spacing-s
	padding-bottom: $spacing-s
	@media #{$bp-desktop}
		gap: $spacing-s

	@media #{$bp-desktop}
		flex-direction: column-reverse

	h4
		margin-bottom: 0
		@media #{$bp-desktop}
			display: none

.fixed-button-req-estimation
	@include fixed-button
	text-align: center
	padding: $spacing-s
	display: flex
	flex-direction: column

	@media #{$bp-desktop}
		position: static
		padding: 0

	&::after
		bottom: 112px

	button
		height: 35px

	#button-not-info
		background: none
		border: none
		color: $color-b4
		font-weight: $font-weight-bold
		cursor: pointer

.topic-form
	padding-top: $spacing-s
	padding-bottom: $spacing-s

	h4
		font-size: $font-size-16
.hidden
	display: none

// Skin Checkbox customization

input[type=checkbox][aria-invalid=true]
	~ .checkbox__icon svg
		fill: var(--select-invalid-border-color, #{$color-r4})
		stroke: var(--select-invalid-border-color, #{$color-r4})

.field__label--attention
	color: var(--select-invalid-border-color, #{$color-r4})

.checkbox
	~ .field__label--end
		margin-left: 8px

	~ div.field__description
		margin-left: 29px

// Skin Textbox upgrade to support text suffixes

.textbox--icon-end
	input.textbox__control
		padding-right: 47px
		text-align: right
		appearance: textfield

	> :last-child
		right: 16px

.textbox > span
	color: var(--textbox-icon-color, #{$color-grey6})
	display: inline-flex
	align-items: center
	height: 100%
	pointer-events: none
	position: absolute
	top: 0


#id_req_telephone
	width: 60%

.field__description--attention
	height: 13px

.info-attention
	display: flex
	flex-direction: row
	align-items: center
	p
		font-size: 14px
		line-height: 20px
		margin-left: 14px
		color: $color-grey7

section.info-attention.mobile
	@media #{$bp-desktop}
		display: none


section.info-attention.desktop
	@media #{$bp-mobile}
		display: none

.expert-feedback
	background: $color-grey2
	h5, p
		color: $color-grey7
		font-size: 16px
		line-height: 24px

	@media #{$bp-desktop}
		border-radius: 8px
		margin-top: 16px
		padding: 24px 0 24px 16px
		background: $color-grey8

		h5
			margin: 0


