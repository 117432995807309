.topic
    @media #{$bp-mobile}
        padding: 0 $spacing-s

    h3
        color: $color-grey7
        font-weight: bold

        @media #{$bp-desktop}
            padding: 0

    h4
        font-size: $font-size-30
        margin-top: 24px
        margin-bottom: 12px

    p
        font-size: $font-size-16
        line-height: 24px

    .number
        height: 40px
        width: 40px
        background-color: #000000
        border-radius: 50%
        color: white
        text-align: center
        vertical-align: middle
        line-height: 40px

    .topic-with-image-wrapper
        display: flex
        flex-direction: column
        margin: 1em 0

        .topic-with-image
            display: flex
            margin-bottom: 0
            margin-top: 1em

            &.image-left
                .image
                    border-top-left-radius: 16px
                    border-bottom-left-radius: 16px
                    @media #{$bp-mobile}
                        border-bottom-left-radius: 0
                        border-top-right-radius: 16px
                .content-wrapper
                    border-top-right-radius: 16px
                    border-bottom-right-radius: 16px
                    @media #{$bp-mobile}
                        border-top-right-radius: 0
                        border-bottom-left-radius: 16px

            &.image-right
                @media #{$bp-mobile}
                    flex-direction: column-reverse
                .image
                    border-top-right-radius: 16px
                    border-bottom-right-radius: 16px
                    @media #{$bp-mobile}
                        border-bottom-right-radius: 0
                        border-top-left-radius: 16px
                .content-wrapper
                    border-top-left-radius: 16px
                    border-bottom-left-radius: 16px
                    @media #{$bp-mobile}
                        border-top-left-radius: 0
                        border-bottom-right-radius: 16px

            @media #{$bp-mobile}
                flex-direction: column

                &.topic-with-image-right
                    flex-direction: column-reverse

            .image
                flex-grow: 1
                flex-basis: 0
                height: 540px
                background:
                    size: cover
                    position: center
                    repeat: no-repeat
                    color: #E5E5E5

                @media #{$bp-mobile}
                    min-height: 500px
                    margin-right: 0
                    img
                        width: 100%

                &.request-estimation
                    background-color: initial
                    background:
                        image: url('../images/introduction/request@1x.png')
                    @media #{$bp-mobile}
                        background:
                            image: url('../images/introduction/request_m.png')

                &.receive-estimation
                    background:
                        image: url('../images/introduction/estimate@1x.png')
                        position-y: bottom
                    @media #{$bp-mobile}
                        background:
                            image: url('../images/introduction/estimate_m.png')

                &.list-on-ebay
                    background-color: initial
                    margin-right: 0
                    background:
                        image: url('../images/introduction/list@1x.png')
                        position-y: bottom
                    @media #{$bp-mobile}
                        background:
                            image: url('../images/introduction/list_m.png')

            &.topic-with-image-right
                .image
                    margin-left: 0
                    margin-right: 0

                    @media #{$bp-mobile}
                        margin-left: 0

            .content-wrapper
                display: flex
                align-items: center
                background-color: $color-grey1
                flex: 1

            .content
                flex-grow: 4
                flex-basis: 0
                padding: 48px

                ul
                    padding-left: 1em
                    margin-left: 0

                > a
                    background-color: #191919

                    margin: 12px 0
                    border: none

        .disclaimer
            color: var(--light-n-7, #191919)
            font-size: $font-size-12

            a
                color: inherit

.view-conditions
    @media #{$bp-desktop}
        max-width: 1280px
        margin: 0 auto

    a
        margin-right: 6px
        color: #111820
        font-weight: bold
        text-decoration: none
    svg path
        position: absolute
        width: 10px
        height: 10px
        left: calc(50% - 10px/2)
        top: calc(50% - 10px/2)
        fill: #111820

.full-width-topic
    @extend .topic

    &.full-width
        max-width: 1600px

.two-column
    display: flex
    flex-direction: column

    @media #{$bp-desktop}
        gap: $spacing-m $spacing-l
        flex-direction: row
        padding-left: $spacing-m
        padding-right: $spacing-m

    .topic
        @media #{$bp-desktop}
            padding-left: 0
            padding-right: 0

    > *
        flex-basis: 100%

.topic-steps
    margin-top: 56px

    @media #{$bp-desktop}
        max-width: 1184px
        margin: 0 auto

    h2
        font-size: $font-size-24
        margin-bottom: 0

        @media #{$bp-desktop}
            font-size: $font-size-30

    p
        color: $color-grey5
        margin-bottom: 0
        margin-top: 8px
        font-size: $font-size-16

.topic-menu
    @media #{$bp-desktop}
        max-width: 1184px
        margin: 40px auto 80px

.topic-tabs
    @media #{$bp-desktop}
        max-width: 1184px
        margin: 0 auto
        margin-top: 80px

.topic-carousel
    margin-top: 56px

    @media #{$bp-desktop}
        max-width: 1184px
        margin: 0 auto
        margin-top: 80px

.topic-categories
    margin-top: 56px

    @media #{$bp-desktop}
        max-width: 1280px
        margin: 0 auto
        margin-top: 80px

.topic-confirmation-page
    padding-top: 30px
    h3
        font-size: $font-size-16
        padding-bottom: 5px

.steps-content
    @media #{$bp-desktop}
        display: table
        width: 100%

.topic-item
    @media #{$bp-desktop}
        display: table-cell
        width: 32%
        padding-right: 55px
    h4
        color: $color-grey7
        margin-bottom: 0px
        font-weight: bold
        font-size: $font-size-20

        @media #{$bp-desktop}
            padding-left: 0
            font-size: $font-size-24
    p
        color: $neutral-n6-color
        margin-top: 10px
        line-height: 20px
        font-size: $font-size-16

        @media #{$bp-desktop}
            padding-left: 0

.dimension-list
    display: flex
    gap: $spacing-l
