.secondary
  padding: 16px
  padding-top: 0px
  max-width: 1280px
  margin: 0 auto

.gradient
  background: linear-gradient(180deg, rgba(17, 24, 32, 0.04) 0%, rgba(196, 196, 196, 0) 69.27%)
  height: 16px
  left: 0px
  right: 0px
  bottom: -16px