@import 'photos'
@import 'estimation-text'

$pending-estimation-gradient-desktop: linear-gradient(90deg, #FBC9CB 0%, #FBC7CA 14.13%, #FAC9CB 28.6%, #F8C6C9 51.04%, #F8C3C7 68.56%, #F8C1C4 83.98%, #FAC5C8 93.08%, #F6BBBF 100%)
$pending-estimation-gradient-mobile: linear-gradient(180deg, #FBC7C9 0%, #FBCDD0 24.93%, #FACCCE 37.03%, #FDC9CB 49.51%, #FDC1C3 63.4%, #FCBCBC 73.31%, #FCB5B3 84.27%, #FBABA9 100%)

.pending-estimation
	height: 314px
	display: flex
	flex-direction: column
	align-items: center
	background:
		color: $color-t7
		repeat: no-repeat
		position-y: bottom
		size: contain

	@media #{$bp-desktop}
		flex-direction: row
		height: 500px
		background:
			color: $color-t7
			position: right center
			
	.svg-waiting-time
		@media #{$bp-desktop}
			margin-right: 186px
			content: url('../svg/store/48h.svg')
	
	.pending-estimation-text
		@extend .full-width-topic
		align-self: center

		@media #{$bp-desktop}
			max-width: 1216px
			margin: 0 auto
			margin-left: 160px

		h2
			margin: 2rem 0 0
			line-height: 46px
			color: white
			@media #{$bp-desktop}
				max-width: 490px
				line-height: 52px
				font-size: 46px

		p
			font-size: $font-size-16
			color: white
			line-height: 28px
			margin: 15px 0 25px

			@media #{$bp-desktop}
				line-height: 24px
				font-size: $font-size-20
				//padding-right: 600px

.estimation-info
	padding-bottom: 3rem

	@media #{$bp-desktop}
		display: flex
		max-width: 1184px
		margin: 0 auto

	> *
		flex-basis: 100%

	&.two-column
		padding: 0

	.pending-estimation-text
		@extend .estimation-text

		.suggested-price-content
			display: none

			@media #{$bp-desktop}
				display: block
				margin-bottom: $spacing-m

		.topic-pending-item
			line-height: 20px

			h4
				color: #767676
				margin-bottom: 0
				margin-top: 30px
				font-weight: normal
				font-size: 14px

			p
				color: $color-grey7
				margin: 0
				margin-top: 2px
				font-size: 14px
				line-height: 20px
				@media #{$bp-desktop}
					font-size: 16px
					line-height: 24px

			p.info-message
				margin: 0
				color: $neutral-n6-color


.pending-images
	@extend .photo-container

.pending-main-image
	@include photo-block

	@media #{$bp-desktop}
		width: 100%
		height: $preview-size*2
		max-height: $preview-max-size*2

	img
		width: 100%
		height: 100%
		object-fit: cover

.pending-other-images
	@extend .photo-list
	margin: 0
	padding-left: 0

	.pending-image
		@include photo-block
		display: inline-flex
		overflow: hidden

		@media #{$bp-desktop}
			margin-right: ($spacing-s - 4px)
			margin-bottom: $spacing-s

			&:nth-child(even)
				margin-right: 0

		img
			height: 100%
			object-fit: cover

.pending-estimation-photos
	h3
		@media #{$bp-desktop}
			display: none

.progress-stepper
	@media #{$bp-desktop}
		width: 100%

.eventpage-section
	background:
		image: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('../images/event-page-image.jpg')
		size: cover
	padding-left: 16px
	margin: 0 auto
	height: 272px
	@media #{$bp-desktop}
		flex-direction: row
		height: 618px
		padding: 16px 32px
		background:
			position: right center
			repeat: no-repeat
			position-y: 18%
		
	.eventpage-section-content
		margin: 0 auto
		max-width: 1184px
		
		a.fake-btn
			background-color: white
			border-color: white
			color: black
			padding: 12px 31px
			margin-top: 24px
		h3
			font-size: 24px
			color: white
			padding-top: 24px
			margin-bottom: 0
			margin-top: 0
			@media #{$bp-desktop}
				font-size: 46px
				padding-top: 0
				margin-top: 195px
		h4
			font-weight: normal