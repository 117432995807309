.notice-cookie
	/* Banner is displayed at the bottom */
	bottom: 0
	left: 0
	position: fixed
	width: 100%
	background: #0063FC
	color: #FFFFFF

	a
		color: inherit

	/* Center the content */
	align-items: center
	display: flex
	justify-content: center

	.banner__content
		/* Take available width */
		max-width: 1280px
		padding: $spacing-xs $spacing-s
		box-sizing: border-box

		@media #{$bp-desktop}
			padding: $spacing-s $spacing-m

.banner-buttons
	display: flex
	align-items: flex-start
	flex-direction: column
	gap: 20px
	padding: 10px

	@media #{$bp-desktop}
		flex-direction: row
		justify-content: end

	a,
	button
		padding: 10px
		text-decoration: none
		border: 1px solid $color-white
		border-radius: 0
		cursor: pointer

#save_cookies_preferences
	float: right

table#objectives
	width: 100%

	td#toggle
		float: right
		margin-top: 42px
		margin-left: 15px
		@media #{$bp-desktop}
			margin-left: inherit

.list_cookies
	margin-bottom: 25%
	margin-bottom: 100px
	@media #{$bp-desktop}
		margin-bottom: inherit

.switch
	width: 60px
	height: 30px
	position: relative
	display: inline-block


.switch input
	display: none

.switch .slider
	position: absolute
	top: 0
	bottom: 0
	right: 0
	left: 0
	cursor: pointer
	background-color: #e7ecf1
	border-radius: 30px !important
	border: 0
	padding: 0
	display: block
	margin: 12px 10px
	min-height: 18px

.switch .slider:before
	position: absolute
	background-color: #aaa
	height: 18px
	width: 18px
	content: ""
	left: 0px
	bottom: 0px
	border-radius: 50%
	transition: ease-in-out .5s

.switch span
	margin-left: -25px
	margin-top: 11px


.yes
	display: none

.no
	display: inline-block

.switch .slider:after
	content: ""
	color: white
	display: block
	position: absolute
	transform: translate(-50%, -50%)
	top: 50%
	left: 70%
	transition: all .5s
	font-size: 10px
	font-family: Verdana, sans-serif

.switch input:checked + .slider:after
	transition: all .5s
	left: 30%
	content: ""

.switch input:checked + .slider
	background-color: #620846


.switch input:checked + .slider:before
	transform: translateX(22px)
	background-color: #d3d6d9

.switch input:checked ~ .yes
	display: inline-block

.switch input:checked ~ .no
	display: none