.estimation-text
	padding-left: $spacing-s

	@media #{$bp-desktop}
		padding-left: 0
		margin-left: 0

	h3
		margin-bottom: 0
		padding-bottom: 0
