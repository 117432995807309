div.estimate-details
  margin-bottom: 100px

  img.estimate-thumbnail
    max-width: 120px
    max-height: 80px

  div.row
    display: inline-flex
    width: 100%
    padding: 20px

    div.row-key
      width: 25%
      max-width: 200px
      font-weight: bold

  div.row:nth-child(odd)
    background-color: #f8f8f8