@import '@ebay/skin/dist/carousel/carousel'

.topic-carousel
    h3
        margin-bottom: 0

    p
        margin-top: 8px
        margin-bottom: 48px
        color: $color-grey5

.carousel
    padding-right: 0
    @media #{$bp-desktop}
        display: table
        width: 100%
    .carousel__container
        .carousel__control
            &:hover
                @media #{$bp-desktop}
                    cursor: pointer

#home-carousel
    display: flex
    @media #{$bp-desktop}
        overflow: hidden
    @media #{$bp-mobile}
        flex-direction: column
    li
        cursor: auto

.carousel__list
    @media #{$bp-desktop}
        padding-left: 0

    a, a:hover, a:visited, a:focus
        color: black

    li
        background-color: $color-grey1
        border-radius: 16px
        margin-right: 8px
        display: flex
        @media #{$bp-desktop}
            flex: 1
            margin-right: 17px
        @media #{$bp-mobile}
            margin-bottom: 17px

        &:hover
            @media #{$bp-desktop}
                cursor: pointer

        .content-wrapper
            margin: 48px 0
            @media #{$bp-mobile}
                margin-bottom: 28px

        svg.icon
            padding-left: 32px

        h4
            font-size: $font-size-24
            line-height: 40px
            font-weight: bold
            max-width: 250px
            color: $color-grey9
            padding: 2px 30px 0 32px
            margin-bottom: 0
            @media #{$bp-desktop}
                max-width: max-content
        p
            font-size: $font-size-16
            line-height: 24px
            color: $color-grey9
            padding: 0 30px 0 32px
            margin-top: 2px
            margin-bottom: 20px
            @media #{$bp-desktop}
                max-width: max-content
