/*******************************************************************************
 *
 *  C2C Estimate - Style conf
 *
 *******************************************************************************/

@use 'sass:math'
@import 'color'
@import 'typo'

$red-ebay: #E53238
$blue-ebay: #0064D2
$yellow-ebay: #F5AF02
$green-ebay: #86B817

$neutral-n1-color: #F5F5F5
$neutral-n5-color: #767676
$neutral-n6-color: #41413F

// Spacings
$spacing-xs: 8px
$spacing-s: $spacing-xs*2
$spacing-m: $spacing-s*2
$spacing-l: $spacing-m*2
$spacing-xl: $spacing-l*2

\:root
  --fake-link-color: #{$color-black}
  --btn-primary-foreground-color: #{$color-white}
