$fixed-submit-button-spacing: 140px

footer
    background-color: $color-grey1

    .footer-content
        padding: $spacing-m 0
        box-sizing: border-box
        max-width: 1184px
        margin: 0 auto
        @media #{$bp-mobile}
            padding: $spacing-m $spacing-s

        .top-inline
            margin-top: 20px
            margin-bottom: 20px
            display: flex
            @media #{$bp-mobile}
                margin-bottom: 10px
            svg
                fill: $color-grey7
                margin-right: 5px
            span
                font-size: $font-size-16
                line-height: 28px
                color: #000000
                margin-right: 5px
                margin-top: -2px
            img
                margin-left: 8px
                max-height: 24px
        span
            font-size: $font-size-16
            line-height: 24px
            color: $color-grey6

        .useful-links
            display: flex
            ul
                list-style-type: none
                padding-left: 0
                margin-right: 35px

                @media #{$bp-mobile}
                    &:last-child
                        margin-right: 0

                li
                    margin-top: 10px
                    a,
                    button
                        text-decoration: none
                        color: $color-grey5
                        font-size: $font-size-12
                        cursor: pointer
                    &:first-child
                        font-weight: bold
                        color: $color-grey7

    .upper-footer
        display: flex
        flex-direction: row
        justify-content: space-between
        @media #{$bp-mobile}
            flex-direction: column
    .lower-footer
        display: flex
        flex-direction: column
        .useful-links
            ul
                a
                    text-decoration: none
    .bottom-footer-social-icons
        @media #{$bp-desktop}
            display: none
            padding-bottom: 25px
            margin: auto
            width: 20%

    .footer-icon
        border-radius: 25px
        width: 350px
        background-color: black
    .icon-social
        display: inline-block
        float: right

        @media #{$bp-mobile}
            float: none
            display: flex

            img
                padding-right: 14px

    .svg-icon-social
        width: 24px
        height: 24px
        padding-top: 10px
