@import '@ebay/skin/dist/section-title/section-title'
@import 'expandable'

.grand-cat-list
    list-style: none
    padding: 0 16px 16px
    padding-left: 0
    @media #{$bp-desktop}
        padding: 0
        padding-bottom: 16px

    h4
        font-size: $font-size-20

    .section-title__title-container, .details__icon
        @media #{$bp-desktop}
            cursor: pointer

    .cat-list
        display: flex
        flex-wrap: wrap
        gap: 16px
        list-style: none
        padding-left: 0

        li
            width: calc(50% - 8px)
            @media #{$bp-desktop}
                width: 22%
            @media #{$bp-desktop-wide}
                width: 24%

    .cat-description-container
        margin-top: -22px

    .cat-description
        margin-top: 0

    .cat-image
        display: flex
        padding: 10px
        height: 34vw
        align-items: center
        justify-content: center
        background: $color-grey1
        border-radius: 6px

        @media #{$bp-desktop}
            height: 10vw
        @media #{$bp-desktop-wide}
            height: 11vw

        img
            object-fit: cover
            width: 100%
            height: 100%

    .cat-title
        margin: 16px 0
        font-size: inherit
        text-align: center
