@import '@ebay/skin/dist/menu-button/menu-button'

.menu.row
	.fake-menu-button
		margin: 8px 0
		display: inline-block
		white-space: nowrap
		--btn-secondary-background-color: var(--light-n-1, #F7F7F7)
		--btn-secondary-border-color: transparent
		--btn-secondary-foreground-color: var(--light-n-7, #191919)
		--color-state-primary-hover: transparent
		--menu-button-menuitem-disabled-foreground-color: var(--menu-button-menuitem-foreground-color, var(--color-foreground-primary))

		button.btn--primary
			font-weight: normal

		.fake-menu-button__menu
			padding-left: 32px
			list-style-type: disc

		a.fake-menu-button__item
			padding: 8px 15px 8px 0
			&[href]:hover
				text-decoration: underline
			&:not([href]):active
				font-weight: initial
