@import 'keen-slider/keen-slider.min.css'

.slider-buttons
	margin: 16px 0
	text-align: right

	@media #{$bp-mobile}
		display: none

	button
		margin-left: 8px

.slider-menu.keen-slider:not([data-keen-slider-disabled])
	flex-flow: row nowrap
	overflow-x: clip
	overflow-y: visible

	.keen-slider__slide
		min-height: initial
		overflow: initial
		position: initial
		width: initial

.slider-tabs.keen-slider:not([data-keen-slider-disabled])
	flex-flow: row nowrap
	//gap: 8px
	padding-left: 0

	.keen-slider__slide
		display: block
		overflow: initial
		width: initial
		flex-shrink: 0

.slider-tabs-panels.keen-slider:not([data-keen-slider-disabled])
	//gap: 16px
	padding-left: 0

	.keen-slider__slide
		display: block
		overflow: initial
		width: initial
		flex-shrink: 0

@media #{$bp-desktop} and (hover: hover)
	.tabs__content:hover .carousel__control
		opacity: .92

@media #{$bp-desktop} and (hover: hover)
	.tabs__content:hover .carousel__control[aria-disabled="true"]
		opacity: .3
