$marketsans-url: "https://ir.ebaystatic.com/cr/v/c1/market-sans/v1.0/"
$marketsans-fontname: "Market Sans"
$marketsans-filename-regular: "MarketSans-Regular-WebS"
$marketsans-filename-bold: "MarketSans-SemiBold-WebS"

$font-family-default: Arial, sans-serif
$font-family-market-sans: $marketsans-fontname, $font-family-default

// primitives
$font-size-12: 0.75rem
$font-size-14: 0.875rem
$font-size-16: 1rem
$font-size-18: 1.125rem // secret size
$font-size-20: 1.25rem
$font-size-24: 1.5rem
$font-size-30: 1.875rem
$font-size-36: 2.25rem

// typeramp
$font-size-giant-2: $font-size-36
$font-size-giant-1: $font-size-30
$font-size-large-2: $font-size-24
$font-size-large-1: $font-size-20
$font-size-medium: $font-size-16
$font-size-regular: $font-size-14
$font-size-small: $font-size-12


// 6.5 Product aliases
$color-background-default: $color-white
$color-background-highlight: $color-grey1
$color-text-default: $color-grey6
$color-text-secondary: $color-grey5
$color-text-disabled: $color-grey4
$color-text-confirmation: $color-g6
$color-separator: $color-grey1
$color-image-border: $color-grey1
$color-action-disabled: $color-grey3
$color-action-primary: $color-b4
$color-action-secondary: $color-grey5
$color-action-hover: $color-b6
$color-action-disabled: $color-grey3
$color-action-secondary: $color-grey5
$color-action-destroy: $color-r4
$color-link-default: $color-action-primary
$color-link-hover: $color-action-hover
$color-link-visited: $color-m4
$color-status-attention: $color-r4
$color-status-confirmation: $color-g4
$color-status-information: $color-b4
$color-disabled: $color-action-disabled

$font-weight-regular: 500
$font-weight-bold: 700
$font-weight-action-primary: bold
$font-weight-dropdown-item-active: bold

$font-size-form-control-large: $font-size-18

$typography-bold-text-font-weight: $font-weight-bold
$typography-secondary-text-color: $color-text-secondary
$typography-emphasis-color: $color-status-attention
$typography-negative-color: $color-status-attention
$typography-positive-color: $color-text-confirmation
$typography-disabled-text-color: $color-text-disabled
$bp-desktop-wide: "only screen and (min-width: 1280px)"
$bp-desktop: "only screen and (min-width: 641px)"
$bp-mobile: "only screen and (max-width: 640px)"
$bp-mobile-small: "only screen and (max-width: 450px)"

body
  font-family: $font-family-market-sans
  font-size: $font-size-14

h2
  font-size: $font-size-36

h3
  font-size: $font-size-24
  @media #{$bp-desktop}
    font-size: $font-size-30
h4
  font-size: $font-size-20
  @media #{$bp-desktop}
    font-size: $font-size-24

hr
  color: var(--color-neutral-2)
  border: 1px solid
