@import 'photos'

.dropzone-preview-block
	@include photo-block


.dropzone-field
	position: relative
	margin: 0 (-$spacing-s)
	padding-left: $spacing-s

	.dropzone-images
		@extend .photo-container

	.dropzone-upload
		@extend .dropzone-preview-block
		display: flex
		flex-direction: column
		gap: $spacing-s
		justify-content: center
		align-items: center
		text-align: center
		cursor: pointer

		@media #{$bp-desktop}
			padding: $spacing-s
			width: 100%
			//aspect-ratio: auto 2/1
			height: $preview-size
			max-height: $preview-max-size

		#id_photos[aria-invalid=true] ~ &
			background-color: $color-textbox-invalid
			background-color: var(--textbox-invalid-background-color, $color-textbox-invalid)
			border-color: $color-r4-dark-mode
			border-color: var(--textbox-invalid-foreground-color, $color-r4-dark-mode)

	.dz-message
		display: none

		@media #{$bp-desktop}
			display: block
			width: 15rem

	.fileinput-button
		pointer-events: none
		padding: 0 5px
		background: $color-white
		--button-icon-only-border-radius: 50%
		border-width: 0

	.dropzone-previews
		@extend .photo-list
		position: relative
		flex-direction: row-reverse
		justify-content: left

		&::before
			@extend .dropzone-preview-block
			position: absolute
			content: ''
			left: 0

		&::after
			@extend .dropzone-preview-block
			position: absolute
			content: ''
			top: 0
			left: $photo-width-mobile + $spacing-s
			z-index: -1

			@media #{$bp-desktop}
				left: auto
				right: 0

	.dz-preview
		@extend .dropzone-preview-block
		position: relative
		display: inline-flex
		justify-content: center
		//flex-shrink: 0
		align-items: center
		vertical-align: middle
		overflow: hidden

		@media #{$bp-desktop}
			margin-right: $spacing-s
			margin-bottom: $spacing-s

			&:nth-child(even)
				margin-right: 0

	.dz-remove
		position: absolute
		bottom: $spacing-xs
		right: $spacing-xs

		&::before
			content: url(../svg/store/icon-delete-mweb.svg)
			
		@media #{$bp-desktop}
			bottom: $spacing-s
			right: $spacing-s

			&::before
				content: url(../svg/store/icon-delete-dweb.svg)

	.field__description--attention
		margin-left: 0
		margin-top: $spacing-xs

	.dz-error-message
		display: none

	.dz-progress
		position: absolute
	
	.dz-upload
		svg.spinner-element
			circle
				transition: stroke-dashoffset 1s linear, opacity 1s 1s
				stroke: #E5E5E5
				stroke-width: .2em

			.progress-bar
				stroke: #3665F3

			.fade-out
				opacity: 0 


	.dz-details
		display: none

	.dz-success-mark
		display: none
	.dz-error-mark
		display: none

	.dz-image

		img
			transition: filter 1s 1s
		.black-white
			filter: grayscale(100%)
